import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../layouts'
import Helmet from 'react-helmet'

import { logo, over_ons_1, bnr, sprout, emerce, salarisnet } from 'images/over-ons'

const OverOnsPage = ({location}) => (
  <Layout location={location}>
    <main className="over-ons">

      <Helmet>
      <title>Over Employes | Missie & visie</title>
      <meta name="description" content="Employes is opgericht om het probleem op te lossen dat je als ondernemer niet zelf je salarisverwerking kunt doen." />
      <meta itemprop="name" content="Online salarisadministratie | Employes" />
      <meta itemprop="description" content="Wij willen je als ondernemer in staat te stellen om zélf je salarisverwerking te doen op een hele makkelijke en transparante manier."/>
      <meta itemprop="image" content="/static/meta-img.png" />
      </Helmet>

      <header className="header padding-xl">
        <div className="container-md">
          <div className="grid yg center text-center">
            <div className="col-12">
              <img src={logo} alt="Employes logo large" className="margin-s-bottom" />
              <p className="streamer large wide center">Salarisadministratie, maar dan simpel.</p>
            </div>
          </div>
        </div>
      </header>

      <section className="mission padding-xl">
        <div className="container-md">
          <div className="grid yg center text-center">
            <div className="col-12 margin-l-bottom">
              <p className="eyebrow">Doelstelling</p>
              <h2>Onze missie</h2>
              <p className="margin-m-bottom">Employes is opgericht om jou als ondernemer in staat te stellen om zélf je HR & salarisadministratie te doen. Wij geloven er namelijk in dat iedereen dat kan met behulp van onze intuïtieve en gebruiksvriendelijke software. </p>
              <p>Je regelt laagdrempelig je loonadministratie, declaraties, verlofaanvragen en meer op een simpele manier. Ons team van veertien enthousiaste professionals staat dagelijks voor jou klaar om je op weg te helpen. </p>
            </div>
          </div>
            <div className="paragraph margin-m-bottom">
              <div className="container-sm image">
                <div className="grid yg center">
                  <div className="col-12 no-pad image-container">
                    <img src={over_ons_1} alt="De oprichters van Employes" className="block" />
                  </div>
                </div>
                <div className="grid yg center margin-s-top">
                  <p>Employes is opgericht door Marius van den Oever & Rob Kroezen.</p>
                  </div>
              </div>
            </div>


          <div className="grid yg center text-center">
            <div className="col-12 margin-l-top">
              <p className="eyebrow">Wie zijn wij</p>
              <h2>Ons team</h2>
              <p className="margin-m-bottom">
                Employes is opgericht door <a href="https://www.linkedin.com/in/employes-robkroezen/" class="link" target="_blank" rel="nofollow">Rob Kroezen</a> (founder) en <a href="https://www.linkedin.com/in/marius-van-den-oever-a3ba456/" target="_blank" rel="nofollow" class="link">Marius van den Oever</a> (technical co-founder). Het is ontstaan vanuit een bestaand salarisadministratie-bedrijf waardoor alle salariskennis in huis is. Wij zijn met een team van 15 personen, waarmee we klaar staan om het verlonen zo soepel mogelijk te laten lopen. Je kunt ons onder andere kennen van:
              </p>

               <img src={bnr} alt="Employes bij bnr" style={{maxWidth:'120px', marginLeft: '20px', marginRight: '20px'}} className="margin-s-bottom" />
                <a href="https://www.sprout.nl/artikel/startup-van-de-week/zo-wordt-employes-de-schrik-van-accountants-door-ondernemers-zelf" target="_blank" rel="nofollow"><img src={sprout} alt="sprout" style={{maxWidth:'120px', marginLeft: '20px', marginRight: '20px'}} className="margin-s-bottom" /></a>
               <a href="https://www.emerce.nl/wire/revolutionaire-nieuwe-tool-ondernemers-opmars-salaris-hr-tool-employes-300-groei-2019" target="_blank" rel="nofollow"><img src={emerce} alt="emerce" style={{maxWidth:'120px',  marginLeft: '20px', marginRight: '20px'}} className="margin-s-bottom" /></a>
              <a href="https://www.salarisnet.nl/2019/10/een-slimme-ondernemer-doet-zijn-eigen-salarisadministratie/" target="_blank" rel="nofollow"><img src={salarisnet} alt="salarisnet"  style={{maxWidth:'120px', marginLeft: '20px', marginRight: '20px'}} className="margin-s-bottom" /></a>
            </div>

          </div>

        </div>
      </section>

      <section className="important padding-xl">
        <div className="container-md">
          <div className="grid center text-center">
            <div className="col-12">
              <p className="eyebrow">Waarden</p>
              <h2 className="margin-xl-bottom">Wat wij belangrijk vinden</h2>
            </div>
          </div>

          <div className="grid yg">
            <div className="col-4">
              <h5>Eenvoud</h5>
              <p>Wij doen er alles aan om salarisadministratie simpel en gebruiksvriendelijk te maken.</p>
            </div>

            <div className="col-4">
              <h5>Betrouwbaarheid</h5>
              <p>Wij zorgen voor een waterdichte salarisadministratie.</p>
            </div>

            <div className="col-4">
              <h5>Innovatie</h5>
              <p>We lanceren regelmatig nieuwe features die het makkelijker maken voor je.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="contact padding-xl-bottom">
        <div className="container-md">
          <div className="content padding-xl">
            <div className="grid center text-center">
              <div className="col-12">
                <p className="eyebrow">Contact</p>
                <h2 className="margin-xl-bottom">Meer informatie?</h2>
              </div>
            </div>

            <div className="grid yg text-center">
              <div className="col-4">
                <h5>Adres</h5>
                <p className="dark">Employes B.V.</p>
                <p className="dark">Wibautstraat 137D</p>
                <p className="dark">1097 DN, Amsterdam</p>
                <p className="dark">(Kvk: 64254615)</p>
              </div>

              <div className="col-4">
                <h5>Email ons</h5>
                <p className="margin-xxs-bottom">
                  <a href="mailto:info@employes.nl" className="link blue">info@employes.nl</a>
                </p>
                <p>
                  <a href="mailto:support@employes.nl" className="link blue">support@employes.nl</a>
                </p>
              </div>

              <div className="col-4">
                <h5>Ondersteuning</h5>
                <p className="dark">Meer hulp nodig? Bekijk voor tips en handige artikelen onze<Link to="/blog/" className="link compact blue">blog.</Link></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </Layout>
)

export default OverOnsPage
